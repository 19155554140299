import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'
const baseUrl = '/manager'

export const getManagerAssignedCredits = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${baseUrl}/users/assigned-credits`,
        {
            params,
            cancelToken,
        },
    )

export const getManagerChildCredits = (childId: string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${baseUrl}/users/${childId}/credits`,
        {
            params,
            cancelToken,
        },
    )

export const getManagerChildCreditHistory = (childId: string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${baseUrl}/users/${childId}/credit-history`,
        {
            params,
            cancelToken,
        },
    )

export const setManagerChildCredits = (childId: string, creditType: 'email' | 'sms' | 'transactional', data: {value: number, note?: string}, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `${baseUrl}/users/${childId}/credits/${creditType}`,
        data,
        {
            params,
            cancelToken,
        },
    )

export const getManagerTemplateAssignedUsers = (templateId: string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${baseUrl}/templates/${templateId}/users`,
        {
            params,
            cancelToken,
        },
    )

export const putManagerTemplateAssignedUsers = (templateId: string, data: { ids: string[] }, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `${baseUrl}/templates/${templateId}/users`,
        data,
        {
            params,
            cancelToken,
        },
    )

export const getManagerNotifications = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${baseUrl}/notifications`,
        {
            params,
            cancelToken,
        },
    )

export const updateManagerNotifications = (data: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `${baseUrl}/notifications`,
        { data },
        {
            params,
            cancelToken,
        },
    )
