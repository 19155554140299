







































































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import { getAssignedEplusLicenses, getOverviewChilds } from '@/api/userapi/manager/users'
import { getManagerAssignedCredits } from '@/api/consoleApi/manager'
import VsPercentageBar from '@/modules/mediaLibrary/components/VsPercentageBar/Index.vue'
import { getUserCredits } from '@/api/consoleApi/user'
import { UserModule } from '@/store/modules/user'
import { formatNumber } from '@/utils/formatter'

@Component({
    name: 'UsersIndex',
    components: {
        VsContainer,
        VsSectionHeader,
        VsPercentageBar,
    },
})
export default class extends Vue {
    loading = false
    overviewChildrenData: any = null
    assignedCredits = {
        email: 0,
        sms: 0,
        transactional: 0,
    }

    eplusLicense = {
        eplusValue: 0,
        eplusAssigned: 0,
    }

    beforeMount () {
        this.getOverviewChildren()
        this.getUserCredits()
        this.getManagerAssignedCredits()
        this.getAssignedEplusLicenses()
    }

    async getOverviewChildren () {
        try {
            const resp = await getOverviewChilds()
            this.overviewChildrenData = resp.data
        } catch (e) {
            this.overviewChildrenData = null
        }
    }

    get userCredits () {
        return UserModule.userCredits
    }

    get eplusTotal () {
        return (this.eplusLicense.eplusValue - this.eplusLicense.eplusAssigned) < 0 ? 0 : this.eplusLicense.eplusValue - this.eplusLicense.eplusAssigned
    }

    private parseAssignedCredits (credit: {key: 'senderplus' | 'sms' | 'email'}) {
        return this.assignedCredits[credit.key === 'senderplus' ? 'transactional' : credit.key] || 0
    }

    private formatAssignedCredits (credit: {key: 'senderplus' | 'sms' | 'email'}) {
        return formatNumber(this.parseAssignedCredits(credit))
    }

    async getAssignedEplusLicenses () {
        try {
            const resp = await getAssignedEplusLicenses()
            this.eplusLicense = resp.data
        } catch (e) {
            this.eplusLicense = {
                eplusValue: 0,
                eplusAssigned: 0,
            }
            console.log(e)
        }
    }

    private async getUserCredits () {
        try {
            const resp = await getUserCredits()
            const credits = resp.data
            UserModule.SET_USER_CREDITS(credits)
        } catch (e) {
            console.log(e)
        }
    }

    private async getManagerAssignedCredits () {
        try {
            const resp = await getManagerAssignedCredits()
            this.assignedCredits = resp.data.data
        } catch (e) {
            console.log(e)
        }
    }

    private parseCreditName (credit: any) {
        switch (credit.key) {
            case 'email': return 'Email'
            case 'sms': return 'Sms'
            case 'senderplus': return this.$t('account.myPlan.transactional')
            default: return credit.name
        }
    }
}
